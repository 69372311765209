import Link from 'next/link'
import React from 'react'

interface CTAProps {
  mainHeading?: string
  subHeading?: string
}

const CTAComponent: React.FC<CTAProps> = ({
  mainHeading = 'Try Supermeme.ai for free today',
  subHeading = 'No Credit Card Required. Your first 10 AI memes are on us.',
}) => {
  return (
    <div className='mx-auto max-w-4xl rounded-lg bg-indigo-700'>
      <div className='px-6 py-12 sm:px-6 sm:py-16 lg:px-8'>
        <div className='mx-auto max-w-2xl text-center'>
          <h2 className='text-3xl font-bold tracking-tight text-white sm:text-4xl'>
            {mainHeading}
          </h2>
          <p className='mx-auto mt-6 max-w-xl text-lg leading-8 text-indigo-200'>
            {subHeading}
          </p>
          <div className='mt-10 flex items-center justify-center gap-x-6'>
            <Link
              href='/login'
              className='w-full max-w-sm rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white'
            >
              Try Now →
            </Link>
          </div>
        </div>
        {/* TODO: add back when app issues are sorted */}
        {/* <img
          src='/google-play-badge.png'
          alt='Get it on Google Play'
          className='h-8'
        />
        <img
          src='/app-store-badge.png'
          alt='Download on the App Store'
          className='h-8'
          /> */}
      </div>
    </div>
  )
}

export default CTAComponent
