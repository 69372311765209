// React
import { useState } from 'react'

// Next
import { useRouter } from 'next/router'

// Components
import { AnimatedInput } from '@/components/blocks/animated-input'

// Icons
import { SearchIcon } from 'lucide-react'

const SimpleSearch: React.FC = () => {
  const router = useRouter()
  const [inputValue, setInputValue] = useState('')

  const submitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    router.push(`/search?query=${inputValue}`)
  }

  return (
    <form onSubmit={submitSearchForm}>
      <div className='flex flex-col items-start space-x-0 space-y-2 md:flex-row md:space-x-2 md:space-y-0'>
        <div className='w-full'>
          <AnimatedInput
            id='meme-search'
            name='meme-search'
            label='Search by describing emotions or actions in 110+ languages'
            value={inputValue}
            placeholders={[
              'Pretending to be happy',
              'Muy cansado',
              'नौकरी से निकाल दिया',
            ]}
            required
            buttonLabel='Search'
            buttonIcon={
              <SearchIcon className='h-5 w-5 text-white' aria-hidden='true' />
            }
            buttonType='submit'
            loading={false}
            onChange={(value) => setInputValue(value)}
          />
        </div>
      </div>
    </form>
  )
}

export { SimpleSearch }
