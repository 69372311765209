import React, { ReactNode } from 'react'
import Link from 'next/link'

interface TextSectionProps {
  h1?: string | string[]
  h2?: string | string[]
  h3?: string | string[]
  h4?: string | string[]
  h5?: string | string[]
  p?: ReactNode | ReactNode[]
  li?: string[] | ReactNode[]
  links?: { href: string; text: string }[]
  className?: string
}

const TextSection: React.FC<TextSectionProps> = ({
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  li,
  links,
  className = '',
}) => {
  const renderHeadings = (
    content: string | string[] | undefined,
    Component: 'h1' | 'h2' | 'h3' | 'h4' | 'h5',
    defaultClassName: string
  ) => {
    if (Array.isArray(content)) {
      return content.map((item, index) => (
        <Component key={index} className={defaultClassName}>
          {item}
        </Component>
      ))
    }
    return content ? (
      <Component className={defaultClassName}>{content}</Component>
    ) : null
  }

  const renderParagraphs = (content: ReactNode | ReactNode[] | undefined) => {
    if (Array.isArray(content)) {
      return content.map((item, index) => (
        <p key={index} className='text-base leading-relaxed text-gray-600'>
          {item}
        </p>
      ))
    }
    return React.isValidElement(content) ? (
      content
    ) : content ? (
      <p className='text-base leading-relaxed text-gray-600'>{content}</p>
    ) : null
  }

  return (
    <div className={`mb-8 ${className}`}>
      {renderHeadings(h1, 'h1', 'mb-6 text-4xl font-bold')}
      {renderHeadings(h2, 'h2', 'mb-4 text-3xl font-semibold')}
      {renderHeadings(h3, 'h3', 'mb-3 text-2xl font-medium')}
      {renderHeadings(h4, 'h4', 'mb-2 text-xl font-medium')}
      {renderHeadings(h5, 'h5', 'mb-2 text-lg font-medium')}
      {renderParagraphs(p)}
      {links &&
        links.map((link, index) => (
          <Link
            key={index}
            href={link.href}
            className='text-blue-600 hover:underline'
          >
            {link.text}
          </Link>
        ))}
      {li && (
        <ul className='list-disc pl-5 text-base leading-relaxed text-gray-600'>
          {Array.isArray(li)
            ? li.map((item, index) => <li key={index}>{item}</li>)
            : li}
        </ul>
      )}
    </div>
  )
}

export default TextSection
