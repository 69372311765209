import {
  FingerprintIcon,
  ImageIcon,
  InstagramIcon,
  LanguagesIcon,
  SparklesIcon,
  SquareCodeIcon,
} from 'lucide-react'

const defaultFeatures = [
  {
    title: 'Quality template database',
    description:
      'High quality images. No watermarks. Upscaled quality. No offensive or NSFW meme templates',
    icon: ImageIcon,
  },
  {
    title: 'True meme generator',
    description:
      'Actually generate memes using AI, not make memes using an image editor',
    icon: SparklesIcon,
  },
  {
    title: 'Social Media Friendly Export',
    description:
      'Export memes in 1:1 or 4:3 ratio based on target social media app',
    icon: InstagramIcon,
  },
  {
    title: 'Custom Watermark',
    description: 'Easily brand your memes without using another image editor',
    icon: FingerprintIcon,
  },
  {
    title: 'Meme APIs',
    description:
      'Build your own meme bot or application with just text input and our meme API',
    icon: SquareCodeIcon,
  },
  {
    title: 'Multilingual search',
    description:
      'Search templates by describing emotions or actions in 110+ languages',
    icon: LanguagesIcon,
  },
]

export default function WhySupermeme({
  features = defaultFeatures,
  title = 'Why Supermeme.ai',
  subtitle = 'Why should you use Supermeme.ai to generate memes over other popular tools?',
  additionalText = '...and no ads!',
}) {
  return (
    <div className='bg-white py-12 sm:py-16'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto max-w-2xl lg:text-center'>
          <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
            {title}
          </h2>
          <p className='mt-6 text-lg leading-8 text-gray-600'>{subtitle}</p>
        </div>
        <div className='mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none'>
          <dl className='grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3'>
            {features.map((feature) => (
              <div key={feature.title} className='flex flex-col'>
                <dt className='flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900'>
                  <feature.icon
                    className='h-5 w-5 flex-none text-indigo-600'
                    aria-hidden='true'
                  />
                  {feature.title}
                </dt>
                <dd className='mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600'>
                  <p className='flex-auto'>{feature.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <p className='mt-16 text-center text-lg font-semibold leading-8 text-gray-600'>
          {additionalText}
        </p>
      </div>
    </div>
  )
}
